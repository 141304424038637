import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

document.addEventListener('DOMContentLoaded', async function () {
  console.log("Document is ready");

  const qsDashboard = document.getElementById('qs_dashboard');
  const embedUrl = qsDashboard.getAttribute('data-embed-url');
  qsDashboard.src = embedUrl;
  console.log("Embed URL:", embedUrl);

  if (!embedUrl) {
    console.error("No embed URL found");
    return;
  }

  console.log("Embedding dashboard");

  const contextOptions = { onChange: (event) => { console.log('CONTEXT-CHANGE-EVENT', event); } };
  const embeddingContext = await createEmbeddingContext(contextOptions);

  const frameOptions = {
    url: embedUrl,
    container: '#qs_dashboard',
    height: "100%",
    width: "100%",
    withIframePlaceholder: true,
    onChange: (changeEvent) => {
      if (changeEvent.eventLevel === 'ERROR') {
        console.log(`Embedding experience failed with "${changeEvent.eventName}"`);
        return;
      }
      switch (changeEvent.eventName) {
        case 'FRAME_MOUNTED':
          console.log("Experience frame is mounted.");
          break;
        case 'FRAME_LOADED':
          console.log("Experience frame is loaded.");
          break;
        case 'FRAME_REMOVED':
          console.log("Experience frame is removed.");
          break;
        default:
          console.log("Change event:", changeEvent);
      }
    }
  };

  const contentOptions = {
    locale: "en-US",
    toolbarOptions: {
      export: true,
      undoRedo: true,
      reset: true
    },
    onMessage: (messageEvent) => {
      switch (messageEvent.eventName) {
        case 'CONTENT_LOADED':
          console.log("Embedded experience is fully loaded.");
          break;
        case 'ERROR_OCCURRED':
          console.log("Embedded experience failed loading.");
          break;
        default:
          console.log("Message event:", messageEvent);
      }
    }
  };

  try {
    const embeddedDashboard = await embeddingContext.embedDashboard(frameOptions, contentOptions);
    console.log("Dashboard embedded successfully", embeddedDashboard);
  } catch (error) {
    console.error("Error embedding dashboard:", error);
  }
});
